var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    [
      _c("b-table", {
        staticClass: "mt-2 table-billing",
        attrs: {
          small: "",
          bordered: "",
          items: _vm.items,
          fields: _vm.fields,
          responsive: "",
          busy: _vm.loading,
        },
        scopedSlots: _vm._u([
          {
            key: "cell(data.license)",
            fn: function (data) {
              return [_vm._v(_vm._s(_vm.showLicense(data.item.data.license)))]
            },
          },
          {
            key: "thead-top",
            fn: function () {
              return [
                _c(
                  "b-tr",
                  [
                    _c("b-th", [_vm._v(_vm._s(_vm.$t("ORGANISATION.TITLE")))]),
                    _c(
                      "b-th",
                      { staticClass: "table-license", attrs: { colspan: "2" } },
                      [_vm._v(_vm._s(_vm.$t("LICENSE.TITLE")))]
                    ),
                    _c(
                      "b-th",
                      { staticClass: "table-service", attrs: { colspan: "6" } },
                      [_vm._v(_vm._s(_vm.$t("BILLING.PARTNER")))]
                    ),
                    _c(
                      "b-th",
                      { staticClass: "table-logins", attrs: { colspan: "3" } },
                      [_vm._v(_vm._s(_vm.$t("BILLING.AUTHENTICATIONS")))]
                    ),
                    _c(
                      "b-th",
                      {
                        staticClass: "table-verifications",
                        attrs: { colspan: "3" },
                      },
                      [_vm._v(_vm._s(_vm.$t("BILLING.VERIFICATIONS")))]
                    ),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
          {
            key: "cell(organisation_name)",
            fn: function (data) {
              return [
                _vm._v(
                  "\n        " + _vm._s(data.item.hostname) + "\n        "
                ),
                _c("small", [
                  _c("br"),
                  _vm._v(_vm._s(data.item.organisation_name)),
                ]),
              ]
            },
          },
          {
            key: "table-busy",
            fn: function () {
              return [
                _c(
                  "div",
                  { staticClass: "text-center my-2" },
                  [
                    _c("b-spinner", {
                      staticClass: "align-middle",
                      attrs: { variant: "secondary" },
                    }),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }